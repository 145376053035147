import StudyChange from "./StudyChange";
import StudyListChange from "./StudyListChange";
import EntityGroupChange from "./EntityGroupChange";
import SiteChange from "./SiteChange";
import AdditionalSitePropertyChange from "./AdditionalSitePropertyChange";
import EventChange from "./EventChange";
import FormChange from "./FormChange";
import SubformTemplateChange from "./SubformTemplateChange";
import FieldChange from "./FieldChange";
import LookupChange from "./LookupChange";
import DynamicLookupChange from "./DynamicLookupChange";
import ValidationRuleChange from "./ValidationRuleChange";
import LayoutChange from "./LayoutChange";
import OrderUpdateChange from "./OrderUpdateChange";

export const loadChange = (change) => {
  if (change.action_type === "order_update") {
    switch (change.section) {
      case "EntityGroup":
        return new OrderUpdateChange(change);
      case "Event":
        return new OrderUpdateChange(change, "events");
      case "Form":
        return new OrderUpdateChange(change);
      case "Layout":
        return new OrderUpdateChange(change, "form_sections");
      default:
        return undefined;
    }
  }
  switch (change.section) {
    case "Study":
      return new StudyChange(change);
    case "StudyList":
      return new StudyListChange(change);
    case "EntityGroup":
      return new EntityGroupChange(change);
    case "Site":
      return new SiteChange(change);
    case "AdditionalSiteProperty":
      return new AdditionalSitePropertyChange(change);
    case "Event":
      return new EventChange(change);
    case "Form":
      return new FormChange(change);
    case "SubformTemplate":
      return new SubformTemplateChange(change);
    case "Field":
      return new FieldChange(change);
    case "Lookup":
      return new LookupChange(change);
    case "DynamicLookup":
      return new DynamicLookupChange(change);
    case "ValidationRule":
      return new ValidationRuleChange(change);
    case "Layout":
      return new LayoutChange(change);
    default:
      throw new Error(
        `Unknown revision section ${change.section}. Add this type to the app/javascript/services/revisions_page/changes/index.js file.`
      );
  }
};

export {
  StudyChange,
  EntityGroupChange,
  SiteChange,
  AdditionalSitePropertyChange,
  EventChange,
  FormChange,
  SubformTemplateChange,
  FieldChange,
  LookupChange,
  ValidationRuleChange,
  LayoutChange,
};
