import ChangeObject, { newOrOriginalAttributes } from "./ChangeObject";

class ValidationRuleChange extends ChangeObject {
  constructor(change) {
    const fieldIdentifier =
      newOrOriginalAttributes(change).additional_subheading_information
        .identifier.value;
    const formOrSubformIdentifier =
      newOrOriginalAttributes(change).subheading_information.identifier.value;
    const ruleType = newOrOriginalAttributes(change).rule_name.value;
    const ruleIdentifier = newOrOriginalAttributes(change).identifier.value;

    const elementLocation = `${formOrSubformIdentifier}.${fieldIdentifier} ${ruleType} (${ruleIdentifier})`;

    const attributeData = [
      ["Type", "rule_name"],
      [
        "Parameters",
        "parameters_for_revision",
        {
          displayFunction: (value) => {
            return value;
          },
          displayExportFunction: (value) => {
            return value.replaceAll("<br>", ", ");
          },
        },
      ],
      // ["PoE", "pre_entry"], // Not displayed
      // ["Post-entry", "post_entry"], // Not displayed
      ["Priority", "priority"],
      ["Custom Message", "parsed_custom_message"],
      ["Condition", "parsed_run_if"],
    ];

    super(change, "ValidationRule", elementLocation, attributeData);
  }
}

export default ValidationRuleChange;
