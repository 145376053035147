import { ActionTypes } from "../../actions/fields_page";

export const addFieldDefaultState = {
  saving: false,
  values: {
    label: "",
    identifier: "",
    type: "",
    widget_type: undefined,
    field_format: undefined,
    precision: undefined,
    range_min: undefined,
    range_max: undefined,
    range_low: undefined,
    range_high: undefined,
    default_presence_validation: true,
    default_disallow_future_validation: true,
  },
  errors: {
    field: {},
    newLookup: {},
    newLookupOptions: {},
  },
  widgetOptions: [],
  newLookup: { identifier: "" },
  newLookupOptions: [],
};

export const addFieldReducer = (state = addFieldDefaultState, action) => {
  const clearedFormState = () => {
    let newState = {
      ...state,
      ...addFieldDefaultState,
    };
    if (action.data) {
      newState["allLookupOptions"] = action.data.lookups;
    }
    return newState;
  };

  const closeForm = () => {
    let newState = {
      ...clearedFormState(),
      showForm: false,
    };
    return newState;
  };

  const clearForm = () => {
    const newState = {
      ...clearedFormState(),
      showForm: true,
    };
    return newState;
  };

  const lookupOptionsFor = (
    allLookupOptions,
    dynamicLookupOptions,
    fieldType
  ) => {
    const filteredLookupOptions = allLookupOptions.filter(
      (option) => option.field_type_filter === fieldType
    );
    const filteredDynamicLookupOptions = dynamicLookupOptions.filter(
      (option) => option.field_type_filter === fieldType
    );
    return [
      { value: "new_lookup", label: "New lookup", fontWeight: "bold" },
      ...filteredLookupOptions,
      ...filteredDynamicLookupOptions,
    ];
  };

  switch (action.type) {
    case ActionTypes.UPDATE_NEW_FIELD_FORM_VALUE: {
      let newValues = {
        ...state.values,
        [action.fieldName]: action.value,
      };

      const newWidgetTypeOptions = state.widgetTypeMap[newValues.type];
      if (action.fieldName === "type") {
        newValues["widget_type"] = newWidgetTypeOptions[0].value;

        // For a decimal field, set a default precision of '1'
        if (action.value === "FormFields::DecimalType") {
          newValues["precision"] = "1";
        }

        const availableFormatOptions = state.availableTypes.find((option) => {
          return option.value === action.value;
        });
        if (
          availableFormatOptions &&
          availableFormatOptions.format_options &&
          availableFormatOptions.format_options.length > 0
        ) {
          newValues["field_format"] =
            availableFormatOptions.format_options[0].value;
        }
      }

      const newState = {
        ...state,
        values: newValues,
        lookupOptions: lookupOptionsFor(
          state.allLookupOptions,
          state.dynamicLookupOptions,
          newValues.type
        ),
        widgetOptions: newWidgetTypeOptions,
      };
      return newState;
    }

    case ActionTypes.HANDLE_OPEN_NEW_FIELD_FORM: {
      let newState = {
        ...state,
        showForm: true,
        lookupOptions: lookupOptionsFor(
          state.allLookupOptions,
          state.dynamicLookupOptions,
          state.values.type
        ),
      };
      return newState;
    }

    case ActionTypes.HANDLE_CLOSE_NEW_FIELD_FORM: {
      return closeForm();
    }

    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUBMIT: {
      let newState = {
        ...state,
        saving: true,
      };
      return newState;
    }

    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUBMIT_AND_CLOSE: {
      let newState = {
        ...state,
        saving: true,
      };
      return newState;
    }

    case ActionTypes.HANDLE_NEW_FIELD_FORM_ERROR: {
      let newState = {
        ...state,
        saving: false,
        errors: {
          field: action.field.json_errors,
          newLookup: action.field.lookup_json_errors,
          newLookupOptions: action.field.lookup_options_json_errors,
        },
      };
      return newState;
    }

    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS: {
      return clearForm();
    }

    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS_AND_CLOSE: {
      return closeForm();
    }

    case ActionTypes.ADD_LOOKUP_OPTION: {
      const newState = { ...state };
      const lookupOptions = [...newState.newLookupOptions];
      lookupOptions.push({ identifier: "", label: "" });
      newState.newLookupOptions = lookupOptions;
      return newState;
    }

    case ActionTypes.REORDER_LOOKUP_OPTION: {
      const newState = { ...state };
      const lookupOptions = [...newState.newLookupOptions];

      const [movedRow] = lookupOptions.splice(action.result.source.index, 1);
      lookupOptions.splice(action.result.destination.index, 0, movedRow);

      newState.newLookupOptions = lookupOptions;
      return newState;
    }

    case ActionTypes.REMOVE_LOOKUP_OPTION: {
      const newState = { ...state };
      const lookupOptions = [...newState.newLookupOptions];
      lookupOptions.splice(action.index, 1);
      newState.newLookupOptions = lookupOptions;
      return newState;
    }

    case "HANDLE_NEW_LOOKUP_VALUE_CHANGE": {
      const newState = { ...state };
      const newLookup = { ...newState.newLookup };
      newLookup[action.field] = action.value;
      newState.newLookup = newLookup;
      return newState;
    }

    case "HANDLE_NEW_LOOKUP_OPTION_VALUE_CHANGE": {
      const newState = { ...state };
      const newLookupOptions = [...newState.newLookupOptions];
      newLookupOptions[action.index][action.field] = action.value;
      newState.newLookupOptions = newLookupOptions;
      return newState;
    }

    // Handle any new lookups being created on the 'Edit Type' modal
    case "EDIT_TYPE_SUBMIT_SUCCESS": {
      let newState = {
        ...state,
        allLookupOptions: action.data.lookups,
        dynamicLookupOptions: action.data.dynamicLookups,
      };
      return newState;
    }
  }

  return state;
};
