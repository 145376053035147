import ChangeObject from "./ChangeObject";

class DynamicLookupChange extends ChangeObject {
  constructor(change) {
    const identifier = change.new_attributes
      ? change.new_attributes.identifier.value
      : change.original_attributes.identifier.value;

    const attributeData = [
      ["Identifier", "identifier"],
      ["Type", "human_lookup_type"],
      [
        "Calculation",
        undefined,
        {
          originalValue:
            change.original_attributes?.library_calculation?.identifier?.value,
          newValue:
            change.new_attributes?.library_calculation?.identifier?.value,
          changed:
            change.original_attributes?.library_calculation?.identifier
              ?.changed ||
            change.new_attributes?.library_calculation?.identifier?.changed,
        },
      ],
    ];

    super(change, "DynamicLookup", identifier, attributeData);
  }
}

export default DynamicLookupChange;
